/* * {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-style: normal;
    font-weight: 400;
} */

.about-top-text{
    color: #707070;
    font-size: 15px;
    line-height: 24px;
}

.team-card{
    margin-top: 30px;
    background: #F9F9F9;
    padding-bottom: 30px;
    border-bottom: 2px solid;
}

.about-image{
     width: 100%;
     height: 100%;
}

.about-col {
    padding: 1.25rem 0 1.25rem 0;
    margin-top: 1.875rem;
    border-left: 0.125rem solid #1D4057;
    background-color: #F9F9F9;
}

.about-col-text {
    background-color: #F9F9F9 !important;
    color: #707070;
    font-size: 15px;
    line-height: 24px;
}

.team-image-zoom{
    height: 15.625rem;
    overflow: hidden;
}

/* .team-image img {
    height: 18.75rem;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.team-image img:hover {
    width: 15.75rem;
    height: 19rem;
} */

.image-box {
    position: relative;
    margin: auto;
    overflow: hidden;
}

.image-box img {
    max-width: 100%;
    display: block;
    width: 100%;
    height: 15.625rem;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    transform: scale(1);
}

.image-box:hover img {
    transform: scale(1.5);
}

.team-text{
    color: #707070;
}

.our-work{
    background-color: #F9F9F9;
    margin-top: 3.125rem;
}

.our-work-card{
    padding: 1.25rem;
    margin-top: 4.875rem;
    margin-bottom: 4.875rem;
    border-left: 0.125rem solid #1D4057;
    background-color: #fff;
}

.section-title{
    font-size: 35px !important;
    font-weight: 500;
    padding: 5px 0 !important;
    /* margin-bottom: 15px; */
    text-align: center;
    /* margin-top: 7%; */
}


@media only screen and (max-width: 600px) {
    .about-image {
        width: 90vw;
    }
    .team-card {
        padding-top: 20px;
    }

    .our-work {
        margin-top: 0.125rem;
    }

    .our-work-card{
        margin-top: 0.875rem;
        margin-bottom: 0.875rem;
    }
}