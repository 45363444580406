.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  font-family: 'Source Sans Pro', sans-serif !important;
  font-style: normal;
  font-weight: 400;
}

.MainTitles{
  font-size: 35px !important;
  font-weight: 500;
  padding: 5px 0 !important;
  margin-bottom: 15px;
}

.ui-icon-triangle-1-s {
  background-color: #ececec;
  background-position: -1px -1px !important;
  border: 1px solid #ddd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent url('./images/accordion.png?ver=2.0.1') no-repeat;
      background-position-x: 0%;
      background-position-y: 0%;
  width: 22px;
  height: 22px;
  position: absolute;
}

.testimonial_content {
  background: #F9F9F9;
  padding: 20px;
  margin-top: 25px;
  border-left: 2px solid #800;
  margin-bottom: 20px;
}

.testimonial_content blockquote {
  display: block;
  padding: 15px 20px 15px 45px;
  position: relative;
  font-size: 16px;
  line-height: 1.2;
  color: #666;
  text-align: justify;
}


.testimonial_content blockquote::before {
  content: "\201C";
  font-family: Georgia, serif;
  font-size: 60px;
  font-weight: bold;
  color: #999;
  position: absolute;
  left: 10px;
  top: 5px;
}

.FooterSocialMediaIcons{
  font-size: 32px;
  margin-left: 10px;
}

.md-accordion .card .card-header a:not(.collapsed) .ui-icon-triangle-1-s {
  background-position: -1px -23px !important;
}

.CollapsedSetupSectionTitle{
  padding-left: 2.4em !important;
  display: block;
  cursor: pointer;
  position: relative;
  margin: 0 !important;
  /* padding: .7em .7em .7em .9em !important; */
      /* padding-left: 0.9em; */
  min-height: 0;
  font-size: 100%;
  color: #000;
}

.CollapsedSetupSectionTitle:hover {
  text-decoration: none !important;
}

.CollapsedSetupSection .accordion .card {
  border: 0px solid rgba(0,0,0,.125) !important ;
  border-bottom: 1px solid rgba(0,0,0,.125) !important ;
}

.card-header {
  padding: .35rem 0rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 0px solid rgba(0,0,0,.125);
}

.MessageInputs{
  background: rgba(144, 144, 144, 0.075);

  padding: 0 1em;
  border: 1px solid #f0f0f0 !important;
  font-family: inherit;
  font-size: 14px;
  border-radius: 0 !important;
  height: 3.5em;
  line-height: 3.5em;
  margin-bottom: 15px !important;
}
.nav-link:hover{
  border-bottom: 1px solid rgb(42, 50, 62);
}

.delay_02s.wow {
  animation-delay: 0.2s !important;
  visibility: visible;
}

.delay_03s.wow {
  animation-delay: 0.3s !important;
  visibility: visible;
}

.delay_04s.wow {
  animation-delay: 0.4s !important;
  visibility: visible;
}

.delay_05s.wow {
  animation-delay: 0.5s !important;
  visibility: visible;
}

.delay_06s.wow {
  animation-delay: 0.6s !important;
  visibility: visible;
}

.delay_07s.wow {
  animation-delay: 0.7s !important;
  visibility: visible;
}


.sm-row-fluid.smue-row-parallax {
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.sm-row-fluid {
  position: relative;
}

.banners {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  background-position: center center;
  background-size: auto auto;
  padding: 10% 0;
}

.smue-button-obj .smue-btn-rounded, .smue-button-inner-obj .smue-btn-rounded, .smue-cta-obj .smue-button-wrap .smue-btn-rounded, .smue-download-button-obj .smue-btn-rounded {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.smue-button-obj .smue-btn-size-middle, .smue-button-inner-obj .smue-btn-size-middle, .smue-cta-obj .smue-button-wrap .smue-btn-size-middle, .smue-download-button-obj .smue-btn-size-middle {
  padding: 10px 23px;
  font-size: 16px;
}

.smue-button-obj .smue-btn-color-silver, .smue-button-inner-obj .smue-btn-color-silver, .smue-cta-obj .smue-button-wrap .smue-btn-color-silver, .smue-download-button-obj .smue-btn-color-silver {
  color: #4f4f4f;
  text-shadow: 0 1px 1px rgba(255,255,255,0.25);
  -webkit-box-shadow: inset 0 0 0 1px #dcdcdc;
  -moz-box-shadow: inset 0 0 0 1px #dcdcdc;
  box-shadow: inset 0 0 0 1px #dcdcdc;
  background-color: #f0f0f0;
  background-image: -moz-linear-gradient(bottom,#e6e6e6,#fefefe);
  background-image: -webkit-gradient(linear,0 100%,0 0,from(#e6e6e6),to(#fefefe));
  background-image: -webkit-linear-gradient(bottom,#e6e6e6,#fefefe);
  background-image: -o-linear-gradient(bottom,#e6e6e6,#fefefe);
  background-image: linear-gradient(to top,#e6e6e6,#fefefe);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe6e6e6',endColorstr='#fffefefe',GradientType=0);
}

.smue-button-obj .smue-btn, .smue-button-inner-obj .smue-btn, .smue-cta-obj .smue-button-wrap .smue-btn, .smue-download-button-obj .smue-btn {
  display: inline-block;
  margin: 10px 0 10px 0;
  /* color: #fff; */
  line-height: 1em;
  text-shadow: 0 1px 1px rgba(21,21,22,0.25);
  text-decoration: none;
  border: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.smue-text-align-center {
  text-align: center;
}

.smue-button-group-obj.smue-button-group-horizontal>.smue-button-inner-obj, .smue-button-group-obj.smue-button-group-horizontal>div[data-smue-shortcode] {
  display: inline-block !important;
  margin-bottom: 0;
}

.smue-button-group-obj.smue-button-group-horizontal.smue-button-group-indent-5 .smue-button-inner-obj {
  margin-left: 5px;
}