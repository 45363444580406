
.footer-lists{
  list-style-type:none;
  margin-top: 1.2rem;
  /* font-size: 10px; */
  line-height: 1.2;
}

.footer-lists li{
  padding: 5px 0px;
  margin-left: -40px;
}